import './Dashboard.css';
import React, { useEffect, useState } from 'react';
import Logout from './Logout';

const Dashboard = () => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const sellerName = localStorage.getItem('username') || 'defaultSeller';

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await fetch(`http://sell.onesole.eu/back/stock.php?seller=${sellerName}`);
                
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setItems(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchItems();
    }, [sellerName]);

    const handleSearch = (event) => {
        const value = event.target.value;
        setSearchQuery(value);
    };
    
    // Filter items based on search query and letter/number-only names
    const filteredItems = searchQuery.length >= 3 
        ? items.filter(item =>
            /^[a-zA-Z0-9\s]+$/.test(item.ProductName) && // Ensure ProductName has only letters, numbers, and spaces
            item.ProductName.toLowerCase().includes(searchQuery.toLowerCase()) // Matches the search query
        )
        : items; // If less than 3 characters, show all items
    

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <section id="dashboard">

            <section id="profile">
                <svg xmlns="http://www.w3.org/2000/svg" height="35px" viewBox="0 -960 960 960" width="48px" fill="black"><path d="M480-481q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42ZM160-160v-94q0-38 19-65t49-41q67-30 128.5-45T480-420q62 0 123 15.5t127.92 44.69q31.3 14.13 50.19 40.97Q800-292 800-254v94H160Zm60-60h520v-34q0-16-9.5-30.5T707-306q-64-31-117-42.5T480-360q-57 0-111 11.5T252-306q-14 7-23 21.5t-9 30.5v34Zm260-321q39 0 64.5-25.5T570-631q0-39-25.5-64.5T480-721q-39 0-64.5 25.5T390-631q0 39 25.5 64.5T480-541Zm0-90Zm0 411Z"/>
                </svg>
                <p id="sName">{sellerName}</p>

                <Logout/>

            </section>

            <section id="nav">
                {/* Search bar */}
            <input 
                type="text" 
                placeholder="Search by product name" 
                value={searchQuery} 
                onChange={handleSearch} 
                id="searchBar"
            />
            </section>

            {/* Display filtered items */}
            <ul id="ulDash">
                {filteredItems.length > 0 ? (
                    filteredItems.map((item) => (
                        <li key={item.ProductID} id="liDash">
                            <img src={item.ImagePath} alt={item.ProductName} style={{ width: '100px' }} />
                            <h3>{item.ProductName}</h3>
                            <p>Price: {item.Price}</p>
                            <p>Size: {item.SizeLabel}</p>
                        </li>
                    ))
                ) : (
                    <p>No items found.</p>
                )}
            </ul>
        </section>
    );
};

export default Dashboard;
