import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';  // For redirection
import './Login.css';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();  // Initialize navigation hook

  const handleSubmit = async (e) => {
      e.preventDefault();

      const loginData = {
          username,
          password
      };

      try {
          const response = await fetch('https://sell.onesole.eu/back/login.php', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(loginData),
          });

          const data = await response.json();

          if (data.success) {
            // Store login token and user info in localStorage
            localStorage.setItem('authToken', data.token);
            localStorage.setItem('username', username);
    
            // Navigate to the dashboard on successful login
            navigate('/dashboard');
          } else {
            // Handle login failure
            alert(data.message || 'Login failed. Please try again.');
          }
        } catch (error) {
          console.error('Error during login:', error);
          alert('An unexpected error occurred. Please try again later.');
        }
      };
  return (
    <main>
      <form className="formC" onSubmit={handleSubmit}>
        <div className="field">
          <input
            className="input"
            name="username"
            type="text"
            placeholder="Username"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="field">
          <input
            className="input"
            name="user_password"
            type="password"
            placeholder="Password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="field">
          <button className="button" type="submit">Login</button>
        </div>
      </form>
    </main>
  );
};

export default Login;
